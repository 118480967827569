import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { ContextParams } from '@wix/platform-editor-sdk';
import type { WidgetId } from '@wix/members-area-app-definitions';

import type { MembersPublicAPI, TFunction } from '../../../types';

import { HistoryLabel } from '../../../constants';
import {
  refreshApp,
  withHistoryFactory,
} from '../../../editor/editor-sdk-wrappers';
import { monitoredTransactionFactory } from '../../../editor/services/monitor';
import { addWidgetsPlugins } from '../services/slots';
import { removeWidgetPlugins } from '../services/widget';
import { navigateToSection } from '../services/navigation';
import { isSplitPlatformAppValid } from '../utils';
import { openSettingsManageAndNavigatePanel } from '../services/manage-and-navigate';
import { globalAppState } from '../../../editor/services';

const initPublicAPI = (
  editorSDK: FlowEditorSDK,
  _contextParams: ContextParams,
): MembersPublicAPI => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const withHistory = withHistoryFactory(editorSDK);

  return {
    addWidgetsPlugins: (integrationApplications, shouldNavigate) => {
      const action = () => {
        return addWidgetsPlugins(
          editorSDK,
          integrationApplications,
          shouldNavigate,
        );
      };

      const actionWithHistory = () => {
        return withHistory(HistoryLabel.WidgetsAdded, action);
      };

      return monitoredTransaction(
        'ma-split.public-api.add-widgets-plugins',
        actionWithHistory,
      );
    },
    removeWidgets: (widgetsIds) => {
      const action = () => removeWidgetPlugins(editorSDK, widgetsIds);

      const actionWithHistory = () => {
        return withHistory(HistoryLabel.WidgetsDeleted, action);
      };

      return monitoredTransaction(
        'ma-split.public-api.remove-widgets',
        actionWithHistory,
      );
    },
    navigateToSection: async (widgetId: WidgetId) => {
      return navigateToSection(editorSDK, widgetId);
    },
    isProfilePageBobValid: () => isSplitPlatformAppValid(editorSDK),
    refreshApp: () => {
      const action = () => refreshApp(editorSDK);
      return monitoredTransaction('ma-split.public-api.refresh-app', action);
    },
    uninstall: () => {
      const action = () => {
        return editorSDK.application.uninstall('', { openConfirmation: false });
      };
      return monitoredTransaction('ma-split.public-api.uninstall', action);
    },
    refreshEditorRoutes: async () => {
      const action = () => {
        return editorSDK.editor.routers.refresh('');
      };
      return monitoredTransaction(
        'ma-split.public-api.refreshEditorRoutes',
        action,
      );
    },
    /** @deprecated Not consumed in the Umbrella application */
    getMembersAreaPageRef: () => {
      throw new Error(
        '[SplitMA] PublicAPI.getMembersAreaPageRef is not implemented',
      );
    },
    /** @deprecated PageUriSEO is modified in the Umbrella application */
    updatePageUriSEO: (pageUriSEO) => {
      throw new Error(
        '[SplitMA] PublicAPI.updatePageUriSEO is not implemented',
      );
    },
    /** @deprecated Routes are stored in the Umbrella application */
    getPublicApplications: async () => {
      throw new Error(
        '[SplitMA] PublicAPI.getPublicApplications is deprecated',
      );
    },
    /** @deprecated Not consumed in the Umbrella application */
    getInstalledWidgetPlugins: () => {
      throw new Error(
        '[SplitMA] PublicAPI.getInstalledWidgetPlugins is not implemented',
      );
    },
    /** @deprecated Routes are stored in the Umbrella application */
    getRoutes: async () => {
      throw new Error('[SplitMA] PublicAPI.getRoutes is deprecated');
    },
    /** @deprecated Routes are stored in the Umbrella application */
    updateRoute: async (_payload) => {
      throw new Error('[SplitMA] PublicAPI.updateRoute is deprecated');
    },
    /** @deprecated Routes are stored in the Umbrella application */
    populateGlobalControllerWithRoutes: async () => {
      throw new Error(
        '[SplitMA] PublicAPI.populateGlobalControllerWithRoutes is deprecated',
      );
    },
    /** @deprecated Profile Card visibility is toggled via Display panel of the widget */
    showProfileCard: async () => {
      throw new Error('[SplitMA] PublicAPI.showProfileCard is deprecated');
    },
    /** @deprecated Profile Card visibility is toggled via Display panel of the widget */
    hideProfileCard: async () => {
      throw new Error('[SplitMA] PublicAPI.hideProfileCard is deprecated');
    },
    /** @deprecated In Split version we do not need to change name of the page */
    renameMembersAreaPage: async (_newPageTitle) => {
      throw new Error(
        '[SplitMA] PublicAPI.renameMembersAreaPage  is deprecated',
      );
    },
    openManageAndNavigatePanel: () => {
      const action = () => {
        const flowAPI = globalAppState.getFlowAPI();
        if (!flowAPI) {
          throw new Error(
            '[Profile Page BoB - Public API] Flow API is missing',
          );
        }
        return openSettingsManageAndNavigatePanel(
          editorSDK,
          flowAPI.translations.t as TFunction,
        );
      };
      return monitoredTransaction(
        'ma-split.public-api.open-manage-and-navigate-panel',
        action,
      );
    },
  };
};

export const createPublicAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): MembersPublicAPI => {
  const getPublicAPI = (() => {
    let _publicAPI: MembersPublicAPI;
    return async () => {
      if (!_publicAPI) {
        _publicAPI = initPublicAPI(editorSDK, contextParams);
      }

      if (!(await isSplitPlatformAppValid(editorSDK))) {
        throw new Error(
          '[Members Area Blocks Platform - Public API] Settings Page or Widget is missing',
        );
      }

      return _publicAPI;
    };
  })();

  return {
    getMembersAreaPageRef: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getMembersAreaPageRef();
    },
    getInstalledWidgetPlugins: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getInstalledWidgetPlugins();
    },
    addWidgetsPlugins: async (integrationApplication, shouldNavigate) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.addWidgetsPlugins(
        integrationApplication,
        shouldNavigate,
      );
    },
    removeWidgets: async (widgetsIds) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.removeWidgets(widgetsIds);
    },
    updatePageUriSEO: async (pageUriSEO) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updatePageUriSEO(pageUriSEO);
    },
    isProfilePageBobValid: async () => {
      const publicAPI = initPublicAPI(editorSDK, contextParams);
      return publicAPI.isProfilePageBobValid();
    },
    navigateToSection: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.navigateToSection(payload);
    },
    uninstall: async () => {
      const publicAPI = initPublicAPI(editorSDK, contextParams);
      return publicAPI.uninstall();
    },
    refreshApp: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshApp();
    },
    refreshEditorRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.refreshEditorRoutes();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    getPublicApplications: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getPublicApplications();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    getRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.getRoutes();
    },
    /** @deprecated Routes are stored in the Umbrella application */
    updateRoute: async (payload) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.updateRoute(payload);
    },
    /** @deprecated Routes are stored in the Umbrella application */
    populateGlobalControllerWithRoutes: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.populateGlobalControllerWithRoutes();
    },
    /** @deprecated Profile Card visibility is toggled via Display panel of the widget */
    showProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.showProfileCard();
    },
    /** @deprecated Profile Card visibility is toggled via Display panel of the widget */
    hideProfileCard: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.hideProfileCard();
    },
    /** @deprecated In Split version we do not need to change name of the page */
    renameMembersAreaPage: async (newPageTitle) => {
      const publicAPI = await getPublicAPI();
      return publicAPI.renameMembersAreaPage(newPageTitle);
    },
    openManageAndNavigatePanel: async () => {
      const publicAPI = await getPublicAPI();
      return publicAPI.openManageAndNavigatePanel();
    },
  };
};
